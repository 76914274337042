@import "./theme.scss";

$font-family: Roboto;

.text-over-flow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin rt-font($fontSize: 14px, $fontWeight: 500, $lineHeight: 16px) {
  font-family: $font-family;
  letter-spacing: 0;
  font-size: $fontSize;
  font-weight: $fontWeight;
  line-height: $lineHeight;
}

.resize-none {
  resize: none;
}

.rt-form {
  ::ng-deep .mat-form-field-underline {
    display: none;
  }

  ::ng-deep .mat-form-field-label {
    padding: 12px !important;
  }

  ::ng-deep
    .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float
    .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float
    .mat-input-server:focus
    .mat-form-field-label-wrapper
    .mat-form-field-label {
    transform: translateY(-1.7em) scale(0.75) perspective(100px)
      translateZ(0.001px) !important;
  }
}

.page-content-wrapper {
  width: 100%;
  height: 100%;
  padding: 16px 16px 0 10px;
  background-color: var(--page-background);
}

.page-content {
  height: calc(100% - 100px) !important;
}

.book-a-test {
  position: fixed;
  top: 28px;
  right: 82px;
}

.test-filter-dialog-open-btn {
  height: 50px !important;
}

.test-list-operations-wrapper {
  position: fixed;
  top: 90px;
  right: 16px;
  height: 50px;
}

.single-icon-wrapper {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 5px;
  cursor: pointer;
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none !important;
}

::ng-deep .custom-dialog {
  padding: 0 !important;
  height: auto;
  overflow: hidden;
}

.form-header {
  color: var(--dialog-text);
  @include rt-font(14px, 500, 16px);
}

.menu-btn {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  @include rt-font(16px, normal, 19px);
  color: var(--left-menu-button);
  padding: 0;
}

@mixin rt-icon-hover(
  $hoverColor: var(--hover),
  $iconColor: var(--color-text),
  $borderRadius: 4px
) {
  color: $iconColor !important;
  padding: 5px;
  box-sizing: border-box;
  border-radius: $borderRadius;
  width: 32px;
  height: 32px;
  @extend .vertical-align-center;
  margin-left: -3px;
  font-size: 23px;

  &:hover {
    background-color: $hoverColor;
  }
}

.horizontal-align-center {
  display: flex;
  justify-content: center;
}

.vertical-align-center {
  display: flex;
  align-items: center;
}

.vert-horiz-align {
  display: flex;
  align-items: center;
  justify-content: center;
}

.horizontal-align-end {
  display: flex;
  justify-content: end;
}

.dark-mode-icon {
  fill: #ffffff;
}

.light-mode-icon {
  fill: #1e1d35;
}

.light-mode-text-icon {
  fill: #1e1d35;
}

::ng-deep mat-dialog-container {
  background-color: var(--color-e-sky-extra) !important;
}

::ng-deep .mat-bottom-sheet-container {
  background-color: var(--color-e-sky-extra) !important;
}

.options-tab-overflow {
  max-width: 1160px;
  overflow-x: auto;
  overflow-y: hidden;
}

// Start Menu Action Buttons View
.menu-action-icon {
  float: left !important;
  margin-right: 7px !important;
  color: var(--color-text);
}

.menu-selection-item-with-warning {
  color: $_A_Error_500 !important;

  mat-icon {
    color: $_A_Error_500 !important;
  }

  svg {
    fill: $_A_Error_500 !important;
    margin-right: 7px !important;
  }
}

button.mat-menu-item {
  width: calc(100% - 12px);
  margin: auto;
  border-radius: 6px;
  height: 46px;
  padding: 6px;
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background-color: var(--hover) !important;
}

.mat-menu-item {
  @include rt-font(14px, 500, 16px);
  @extend .vertical-align-center;
}

// End Menu Action Buttons View

// Start Custom Table

.table-wrapper,
table {
  width: 100%;
}

.custom-table-wrapper mat-cell:last-of-type,
.custom-table-wrapper mat-header-cell:last-of-type,
.custom-table-wrapper mat-footer-cell:last-of-type {
  padding-left: 0 !important;
  width: 20px;
  margin-top: 10px;
}

.custom-table-wrapper mat-cell:first-of-type,
.custom-table-wrapper mat-header-cell:first-of-type,
.custom-table-wrapper mat-footer-cell:first-of-type {
  padding-right: 0 !important;
  color: var(--color-text);
}

.custom-table-wrapper tr.mat-header-row {
  background-color: var(--list-header);
  height: 30px !important;
}

.custom-table-wrapper tr.mat-row,
.custom-table-wrapper tr.mat-footer-row {
  height: 46px !important;
  color: var(--header) !important;
  @include rt-font(14px, normal, 16px);

  &:hover {
    background-color: var(--hover);
  }
}

.custom-table-wrapper th.mat-header-cell {
  color: var(--list-header-text);
  @include rt-font(14px, normal, 16px);
}

.custom-table-wrapper th.mat-header-cell,
.custom-table-wrapper td.mat-footer-cell {
  border-bottom: none !important;
}

.custom-table-wrapper td.mat-cell {
  border-bottom: 1px solid var(--tab-border);
  color: var(--left-menu-button);
}

.custom-table {
  background-color: transparent !important;
}

// End Custom Table

.form-row {
  margin-bottom: 10px !important;
}

.form-label {
  color: var(--dialog-text) !important;
  @include rt-font(14px, 500, 16px);
  margin-bottom: 3px !important;
}

::ng-deep
  .ag-theme-material
  .ag-header-cell:not(.ag-column-resizing)
  + .ag-header-cell:not(.ag-header-cell-moving):hover {
  background-color: var(--hover) !important;
}

::ng-deep .ag-theme-material .ag-row:hover {
  background-color: var(--hover) !important;
}

.export-data-menu > ::ng-deep .mat-menu-panel {
  width: 200px !important;
  max-width: 200px !important;
}

::ng-deep .ag-theme-material input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-dialog-header) !important;
}

::ng-deep .ag-theme-material input:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-dialog-header) !important;
}

::ng-deep .ag-theme-material input:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-dialog-header) !important;
}

::ng-deep .ag-center-cols-viewport {
  overflow-x: hidden !important;

  @media (max-width: 768px) {
    overflow-x: auto !important;
  }
}

.filter-text-inbox-wrapper {
  position: relative;
  margin-right: 16px;

  input {
    height: 40px !important;
    width: 450px !important;
    border-radius: 6px;
    box-shadow:
      0 0 1px 0 rgba(0, 0, 0, 0.04),
      0 2px 1px -1px rgba(0, 0, 0, 0.08);
    background-color: var(--left-menu-background) !important;
  }

  input::placeholder {
    color: $Form_Icon_Cancel;
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $Form_Icon_Cancel;
  }

  input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $Form_Icon_Cancel;
  }

  mat-icon {
    position: absolute;
    top: 9px;
    right: 18px;
    color: $Form_Icon_Cancel;
  }
}

:root {
  --login-right-block-bg: #{$_02_C_Text_Ligher};
  --login-project-desc: #{$_01_E_Sky_Extra_Light};
  --room-settings-values-sub-title: #{$_01_G_Dark};
  --status-line-background-hover: #{$_01_A_Sky_Light};
  --left-menu-background: #{$_01_E_Sky_Extra_Light};
  --primary: #{$Primary_Light};
  --left-menu-button: #{$_01_G_Dark};
  --hover: #{$_01_B_Sky_Light};
  --icon-color: #{$_01_C_Sky_Light};
  --login-background: #{$_01_G_Dark};
  --background: #{$_01_E_Sky_Extra_Light};
  --header: #{$_01_G_Dark};
  --list-header: #{$_01_A_Sky_Light};
  --list-header-text: #{$Table_Header};
  --disabled-text: #{$_01_D_Sky_Lighter};
  --table-shadow-1: #{$Table_Light_Shadow_08};
  --table-shadow-2: #{$Table_Light_Shadow_10};
  --sub-menu-text: #{$Dark_Text_Color};
  --page-background: #{$_01_A_Sky_Light};
  --header-icon-hover: #{$Light_Hover};
  --dialog-background: #{$_01_E_Sky_Extra_Light};
  --dialog-text: #{$_01_C_Sky_Dark};
  --dialog-icon-color: #{$Form_Icon_Cancel};
  --info-table-border: #{$_01_B_Sky_Light};
  --info-table-header: #{$_01_B_Sky_Light};
  --note-edit-icon-border: #{$_01_B_Sky_Light};
  --radio-btn-text: #{$Radio_Btn_Text_Dark};
  --icon-shadow-1: #{$Add-Graf_Light_Icon_Shadow};
  --icon-shadow-2: #{$Add-Graf_Light_Icon_Shadow_2};
  --udaq-list-border: #{$_01_B_Sky_Light};
  --tab-border: #{$_01_B_Sky_Light};
  --graph-header-bg: #{$_01_E_Sky_Extra_Light};
  --step-btn: #{$Primary_Light};
  --user-card: #{$_01_E_Sky_Extra_Light};
  --menu-item-divider: #{$Light_Hover};
  --sub-level-count: #{$_01_C_Sky_Dark};
  --station-info-dialog: #{$_01_G_Dark};
  --station-card-bg: #{$_03_A_Primary_500_06};
  --prog-spinner-main: #{$Prog_Spinner_Light};
  --tab-bg: #{$_01_E_Sky_Extra_Dark};
  --test-detail-btn-hover: #{$_01_E_Sky_Extra_Light_06};
  --content-box-background: #{$_01_A_Sky_Light};
  --content-box-text: #{$_02_A_Text_Light};
  --menu-input-header: #{$_02_B_Text_Light};
  --menu-input-background: #{$_01_A_Sky_Light};
  --graph-icons: #{$_02_B_Text_Light};
  --plot-result-count-text: #{$_02_C_Text_Ligher};
  --graph-wrapper-border: #{$_01_C_Sky_Light};
  --room-settings-divider: #{$_01_C_Sky_Light};
  --dark-statistics-card: #{$_01_G_Dark};
  --statistics-card-little-number: #{$_02_C_Text_Ligher};
  --instant-availability-spinner-border: #{$Instant_Availability_Spinner_Border_White};
  --refrigerator-history-box-background: #{$_01_D_Sky_Lighter_08};
  --refrigerator-history-box-content-text: #{$Refrigerator_History_Box_Content_Text_White};
  --timeout-icon: #{$Primary_Light};
  --station-available-text: #{$_02_C_Text_Ligher};
  --station-available-card-border: #{$_01_C_Sky_Light};
  --station-not-available-bg: #{$Station_Not_Available_Bg_Light};
  --settings-table-header-text: #{$Table_Header};
  --add-new-language-btn-text: #{$_02_A_Text_Light};
  --add-new-language-btn-bg: #{$_01_E_Sky_Extra_Light};
  --add-new-language-desc: #{$Refrigerator_History_Box_Content_Text_White};
  --channel-desc-border: #{$_01_C_Sky_Light};
  --settings-custom-btn: #{$_01_B_Sky_Light};
  --station-list-item: #{$_Station_List_Item_Light};
  --left-menu-button-bg: #{$left_menu_button_light};
  --secondary-label: #{$Primary_Light};
  --color-loading-bg: #{$Black_60};
  --card-loading: #{$Loading_Light};
  --table-badge-text: #{$_01_E_Sky_Extra_Light};
  --establishment-header-background1: #{$_04_A_RTTC_030};
  --establishment-header-background2: #{$_07_A_FLEX_030};
  --establishment-header-background3: #{$_05_A_RTS_030};
  --establishment-header-background4: #{$_06_A_RLS_030};
  --notification-description: #{$Notification_Description_light};
  --notification-item-hover: #{$_01_A_Sky_Light};
  --notification-item-border: #{$_01_B_Sky_Light};
  --notification-button-color: #{$_01_E_Sky_Extra_Light};
  --notification-list-icon-color: #{$_01_C_Sky_Light};
  --voltage-settings-bg: #{$Station_Card_Light};
  --voltage-selectable-item: #{$_01_E_Sky_Extra_Light};
  --room_settings_planing_dialog_choose_box_icon: #{$Room_Settings_Planing_Icon_Color};
  --room_settings_planing_dialog_choose_arrow-icon: #{$_02_C_Text_Ligher};
  --gate-test-accordion-expand-icon: #{$Form_Icon_Cancel};
  --gate-test-mat-accordion-test-card-border-color-one: #{$Gate_Test_Mat_Accordion_Test_Cards_Border_Color_One_White};
  --gate-test-mat-accordion-test-cards-title-text-color-one: #{$Primary_Light};
  --gate-test-mat-accordion-test-card-border-color-two: #{$_06_D_RLS_300};
  --gate-test-mat-accordion-test-card-border-color-three: #{$Gate_Test_Mat_Accordion_Test_Cards_Border_Color_Three_White};
  --gate-test-mat-accordion-test-cards-title-text-color-three: #{$Gate_Test_Mat_Accordion_Test_Cards_Title_Text_Color_Three_White};
  --selected-template-border-color: #{$Custom_Primary};
  --launcher-dialog-wrapper-bg: #{$_01_E_Sky_Extra_Light};
  --launcher-dialog-wrapper-border: #d2d2d6;
  --launcher-dialog-icon-color: #{$_01_G_Dark};
  --launcher-dialog-close-icon-wrapper-bg: #{$_01_B_Sky_Light};
  --launcher-dialog-close-icon-wrapper-hover-bg: #{$_01_D_Sky_Lighter};
  --launcher-dialog-selected-test-bg: #{$_01_B_Sky_Light};
  --launcher-dialog-test-shiny-text-color: #{$_01_G_Dark};
  --launcher-dialog-test-pale-text-color: #93939f;
  --delete-all-range-selections-btn-bg: transparent;
  --delete-all-range-selections-btn-text: #{$_A_Error_500};
}

.dark {
  --login-right-block-bg: #{$_01_D_Sky_Darker};
  --login-project-desc: #{$_02_E_Text_Extra_Lighter};
  --room-settings-values-sub-title: #{$_02_C_Text_Darker};
  --status-line-background-hover: #{$_01_C_Sky_Dark};
  --left-menu-background: #{$_01_E_Sky_Extra_Dark};
  --primary: #{$Primary_Dark};
  --left-menu-button: #{$_01_E_Sky_Extra_Light};
  --hover: #{$_01_F_Sky_Extra_Dark};
  --icon-color: #{$_01_B_Sky_Dark};
  --login-background: #{$_01_G_Dark};
  --background: #{$_01_G_Dark};
  --header: #{$_01_E_Sky_Extra_Light};
  --list-header: #{$_01_D_Sky_Darker};
  --list-header-text: #{$_02_C_Text_Darker};
  --disabled-text: #{$_02_D_Text_Extra_Dark};
  --table-shadow-1: #{$Table_Dark_Shadow_20};
  --table-shadow-2: #{$Table_Dark_Shadow_15};
  --sub-menu-text: #{$_01_E_Sky_Extra_Light};
  --page-background: #{$_01_G_Dark};
  --header-icon-hover: #{$_01_D_Sky_Darker};
  --dialog-background: #{$_01_E_Sky_Extra_Dark};
  --dialog-text: #{$_02_B_Text_Dark};
  --dialog-icon-color: #{$_01_E_Sky_Extra_Light};
  --info-table-border: none;
  --info-table-header: #{$_01_D_Sky_Darker};
  --note-edit-icon-border: #{$_01_C_Sky_Dark};
  --radio-btn-text: #{$_01_E_Sky_Extra_Light};
  --icon-shadow-1: #{$Add-Graf_Dark_Icon_Shadow};
  --icon-shadow-2: #{$Add-Graf_Dark_Icon_Shadow_2};
  --udaq-list-border: #{$_01_B_Sky_Dark};
  --tab-border: #{$_01_B_Sky_Dark};
  --graph-header-bg: #{$_01_E_Sky_Extra_Dark};
  --step-btn: #{$_01_E_Sky_Extra_Light};
  --user-card: #{$_01_D_Sky_Darker};
  --menu-item-divider: #{$_01_B_Sky_Dark};
  --sub-level-count: #{$_01_E_Sky_Extra_Light};
  --station-info-dialog: #{$_01_D_Sky_Darker};
  --station-card-bg: #{$_01_E_Sky_Extra_Dark};
  --prog-spinner-main: #{$_01_E_Sky_Extra_Light};
  --test-detail-btn-hover: #{$_01_E_Sky_Extra_Dark};
  --content-box-background: #{$_01_C_Sky_Dark};
  --content-box-text: #{$_02_A_Text_Dark};
  --menu-input-header: #{$_02_B_Text_Dark};
  --menu-input-background: #{$_01_F_Sky_Extra_Dark};
  --graph-icons: #{$_02_A_Text_Dark};
  --plot-result-count-text: #{$_02_B_Text_Dark};
  --graph-wrapper-border: none;
  --room-settings-divider: #{$_01_E_Sky_Extra_Light};
  --dark-statistics-card: #{$_01_E_Sky_Extra_Dark};
  --statistics-card-little-number: #{$_01_E_Sky_Extra_Light};
  --instant-availability-spinner-border: #{$Instant_Availability_Spinner_Border_Dark};
  --refrigerator-history-box-background: #{$_01_D_Sky_Darker};
  --refrigerator-history-box-content-text: #{$_02_A_Text_Dark};
  --timeout-icon: #{$Timeout_Icon_Dark};
  --station-available-text: #{$Station_Available_Text_Dark};
  --station-available-card-border: #{$_01_C_Sky_Dark};
  --station-not-available-bg: #{$Station_Not_Available_Bg_Dark};
  --settings-table-header-text: #{$_02_C_Text_Darker};
  --add-new-language-btn-text: #{$_02_B_Text_Dark};
  --add-new-language-btn-bg: #{$_01_F_Sky_Extra_Dark};
  --add-new-language-desc: #{$_01_E_Sky_Extra_Light};
  --channel-desc-border: #{$_01_D_Sky_Darker};
  --settings-custom-btn: none;
  --station-list-item: #{$_Station_List_Item_Dark};
  --left-menu-button-bg: #{$_01_D_Sky_Darker};
  --secondary-label: #{$Primary_Dark};
  --color-loading-bg: #{$_01_D_Sky_Darker_60};
  --card-loading: #{$Loading_Dark};
  --table-badge-text: #{$_02_C_Text_Darker};
  --establishment-header-background1: #{$_04_A_RTTC_030_40};
  --establishment-header-background2: #{$_07_A_FLEX_030_40};
  --establishment-header-background3: #{$_05_A_RTS_030_40};
  --establishment-header-background4: #{$_06_A_RLS_030_40};
  --notification-description: #{$Notification_Description_dark};
  --notification-item-hover: #{$_01_D_Sky_Darker};
  --notification-item-border: #{$_01_C_Sky_Dark};
  --notification-button-color: #{$_01_D_Sky_Darker};
  --notification-list-icon-color: #{$_01_C_Sky_Dark};
  --voltage-settings-bg: #{$_01_C_Sky_Dark};
  --voltage-selectable-item: #{$_01_B_Sky_Dark};
  --room_settings_planing_dialog_choose_box_icon: #{$_02_A_Text_Dark};
  --room_settings_planing_dialog_choose_arrow-icon: #{$Notification_Description_dark};
  --gate-test-accordion-expand-icon: #{$Notification_Description_dark};
  --gate-test-mat-accordion-test-card-border-color-one: #{$Gate_Test_Mat_Accordion_Test_Cards_Border_Color_One_Dark};
  --gate-test-mat-accordion-test-cards-title-text-color-one: #{$Gate_Test_Mat_Accordion_Test_Cards_Title_Text_Color_One_Dark};
  --gate-test-mat-accordion-test-card-border-color-two: #{$Gate_Test_Mat_Accordion_Test_Cards_Border_Color_Two_Dark};
  --gate-test-mat-accordion-test-card-border-color-three: #{$_04_D_RTTC_300};
  --gate-test-mat-accordion-test-cards-title-text-color-three: #{$Primary_Dark};
  --selected-template-border-color: #{$Primary_Dark};
  --launcher-dialog-wrapper-bg: #{$_01_E_Sky_Extra_Dark};
  --launcher-dialog-wrapper-border: #{$_01_C_Sky_Dark};
  --launcher-dialog-icon-color: #{$_01_E_Sky_Extra_Light};
  --launcher-dialog-close-icon-wrapper-bg: #{$_01_D_Sky_Darker};
  --launcher-dialog-close-icon-wrapper-hover-bg: #{$_01_C_Sky_Dark};
  --launcher-dialog-selected-test-bg: #{$_01_D_Sky_Darker};
  --launcher-dialog-test-shiny-text-color: #{$_01_E_Sky_Extra_Light};
  --launcher-dialog-test-pale-text-color: #{$Notification_Description_dark};
  --delete-all-range-selections-btn-bg: #{$_A_Error_500};
  --delete-all-range-selections-btn-text: #{$_01_E_Sky_Extra_Light};
}
