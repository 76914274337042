// Success
$_A_Success_500: #47d849;
$_A_Success_500_70: rgba(#47d849, 0.7);
$_A_Success_500_40: rgba(#47d849, 0.4);
$_A_Success_500_15: rgba(#47d849, 0.15);
$_A_Success_500_06: rgba(#47d849, 0.06);

// Warning
$_A_Warning_500: #f4bf00;
$_A_Warning_500_70: rgba(#f4bf00, 0.7);
$_A_Warning_500_40: rgba(#f4bf00, 0.4);
$_A_Warning_500_15: rgba(#f4bf00, 0.15);
$_A_Warning_500_06: rgba(#f4bf00, 0.06);

// Error
$_A_Error_500: #d82a03;
$_A_Error_500_70: rgba(#d82a03, 0.7);
$_A_Error_500_40: rgba(#d82a03, 0.4);
$_A_Error_500_15: rgba(#d82a03, 0.15);
$_A_Error_500_06: rgba(#d82a03, 0.06);

// Info
$_A_Info_500: #02adf7;
$_A_Info_500_70: rgba(#02adf7, 0.7);
$_A_Info_500_40: rgba(#02adf7, 0.4);
$_A_Info_500_15: rgba(#02adf7, 0.15);
$_A_Info_500_06: rgba(#02adf7, 0.06);

// Rota
$_03_A_Primary_030: #e8eaf9;
$_03_A_Primary_100: #d9dcf7;
$_03_A_Primary_300: #848acf;
$_03_A_Primary_500: #262a5f;
$_03_A_Primary_600: #1b1f51;
$_03_A_Primary_800: #0c0e37;
$_03_A_Primary_500_70: rgba(#262a5f, 0.7);
$_03_A_Primary_500_40: rgba(#262a5f, 0.4);
$_03_A_Primary_500_15: rgba(#262a5f, 0.15);
$_03_A_Primary_500_06: rgba(#262a5f, 0.06);

// Sky Dark
$_01_A_Sky_Dark: #e9e8eb;
$_01_B_Sky_Dark: #616171;
$_01_C_Sky_Dark: #4b4a5d;
$_01_D_Sky_Darker: #39384d;
$_01_E_Sky_Extra_Dark: #2f2e44;
$_01_F_Sky_Extra_Dark: #26253c;
$_01_G_Dark: #1e1d35;
$_01_D_Sky_Darker_60: rgba(#39384d, 0.6);

// Sky Light
$_01_A_Sky_Light: #f6f6f7;
$_01_B_Sky_Light: #ededef;
$_01_C_Sky_Light: #dbdbde;
$_01_D_Sky_Lighter: #b7b6be;
$_01_E_Sky_Extra_Light: #ffffff;
$_01_E_Sky_Extra_Light_15: rgba(#ffffff, 0.15);
$_01_E_Sky_Extra_Light_06: rgba(#ffffff, 0.06);
$_01_D_Sky_Lighter_08: rgba(#b7b6be, 0.08);

// Text Dark
$_02_A_Text_Dark: #ffffff;
$_02_B_Text_Dark: #a5a5ae;
$_02_C_Text_Darker: #787786;
$_02_D_Text_Extra_Dark: #616071;
$_02_E_Text_Extra_Extra_Dark: #4b4a5d;

// Text Light
$_02_A_Text_Light: #1e1d35;
$_02_B_Text_Light: #4b4a5d;
$_02_C_Text_Ligher: #9c9ca6;
$_02_D_Text_Extra_Light: #b7b6be;
$_02_E_Text_Extra_Lighter: #f3fbfe;

// RLS Dark
$Primary_Dark: #f5a200;
$Primary_70_Dark: rgba(#f5a200, 0.7);
$Primary_40_Dark: rgba(#f5a200, 0.4);
$Primary_15_Dark: rgba(#f5a200, 0.15);
$Primary_06_Dark: rgba(#f5a200, 0.08);

// RLS Light
$Primary_Light: #1675e9;
$Primary_70_Light: rgba(#1675e9, 0.7);
$Primary_40_Light: rgba(#1675e9, 0.4);
$Primary_15_Light: rgba(#1675e9, 0.15);
$Primary_06_Light: rgba(#1675e9, 0.08);

// Custom Light
$Custom_01_Sky_Light: #6f6f7e;
$Custom_02_Sky_Light: #939d9a;

// Custom Dark
$Custom_01_Sky_Dark: #232239;

$_06_D_RLS_300: #f8836f;
$_04_D_RTTC_300: #fcd364;

// EKSİK RENK KODU (https://rotateknik.invisionapp.com/console/Rota-Design-System-Light-ckkz5l5zx066t012d192r5n3o/cklajk7ek00oi013x119wdyq8/inspect) PRİMARY LABEL
$Custom_Primary: #2864dd;
$Form_Icon_Cancel: #67697a;

$Error_Dark: #d12310;

$Feedback_Shadow_Light: rgba(0, 0, 0, 0.12);
$Feedback_Shadow_Dark: rgba(4, 3, 14, 0.35);
$Feedback_Success_Background_Light: #ffffff;
$Feedback_Success_Background_Dark: #2f2e44;
$Feedback_Embedded_Background_Light: #f6f6f7;
$Feedback_Embedded_Background_Dark: #1e1d35;

$Black_60: rgba(#000000, 0.6);
$White_90: rgba(#fff, 0.9);

$Custom_Gray: #e6e7e9;

$Table_Header: #676976;

$Table_Dark_Shadow_20: rgba(4, 3, 14, 0.2);
$Table_Dark_Shadow_15: rgba(4, 3, 14, 0.15);

$Table_Light_Shadow_08: rgba(0, 0, 0, 0.08);
$Table_Light_Shadow_10: rgba(0, 0, 0, 0.1);

$Add-Graf_Dark_Icon_Shadow: rgba(4, 3, 14, 0.15);
$Add-Graf_Light_Icon_Shadow: rgba(0, 0, 0, 0.04);

$Add-Graf_Dark_Icon_Shadow_2: rgba(4, 3, 14, 0.2);
$Add-Graf_Light_Icon_Shadow_2: rgba(0, 0, 0, 0.08);

$Dark_Text_Color: #1c2038;
$Light_Hover: #e6e7ed;

$Radio_Btn_Text_Dark: #1c2038;

$Station_Card_Light: #f2f3f6;

$Prog_Spinner_Light: #1e1d35;

$Instant_Availability_Spinner_Border_White: #f2f2f3;
$Instant_Availability_Spinner_Border_Dark: #29283f;

$Refrigerator_History_Box_Content_Text_White: #3b3c40;

$Timeout_Icon_Dark: #f6a200;
$Station_Available_Text_Dark: #f5a200b3;

$Station_Not_Available_Bg_Light: rgba(#1675e9, 0.08);
$Station_Not_Available_Bg_Dark: rgba(#f6a200, 0.06);

$_Station_List_Item_Dark: #3f373e;
$_Station_List_Item_Light: #edf4fe;
$left_menu_button_light: #eeedee;

$Loading_Dark: #3e3d50;
$Loading_Light: #f5f5f5;

$Notification_Description_dark: #9595a2;
$Notification_Description_light: #8b8d94;

// Establishment Card BG 1
$_04_A_RTTC_030: #fff8e0;
$_04_A_RTTC_030_40: rgba(246, 162, 0, 0.4);

// Establishment Card BG 2
$_07_A_FLEX_030: #e4fde5;
$_07_A_FLEX_030_40: rgba(31, 192, 84, 0.4);

// Establishment Card BG 3
$_05_A_RTS_030: #e6f0fe;
$_05_A_RTS_030_40: rgba(40, 100, 221, 0.4);

// Establishment Card BG 4
$_06_A_RLS_030: #ffe9e3;
$_06_A_RLS_030_40: rgba(234, 19, 26, 0.4);

// Room Settings Planing Icon Color
$Room_Settings_Planing_Icon_Color: #323b48;

// Gate Test Mat Accordion Test Cards Border Color One
$Gate_Test_Mat_Accordion_Test_Cards_Border_Color_One_White: #71baf8;
$Gate_Test_Mat_Accordion_Test_Cards_Border_Color_One_Dark: #62cef5;

// Gate Test Mat Accordion Test Cards Title Text Color One
$Gate_Test_Mat_Accordion_Test_Cards_Title_Text_Color_One_Dark: #008ae0;

// Gate Test Mat Accordion Test Cards Border Color Two
$Gate_Test_Mat_Accordion_Test_Cards_Border_Color_Two_Dark: #fba07f;

// Gate Test Mat Accordion Test Cards Border Color Three
$Gate_Test_Mat_Accordion_Test_Cards_Border_Color_Three_White: #ffe566;

// Gate Test Mat Accordion Test Cards Title Text Color Three
$Gate_Test_Mat_Accordion_Test_Cards_Title_Text_Color_Three_White: #ffc900;
